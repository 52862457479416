.dialog-header {
    flex: 0 0 auto;
    margin: 0;
    padding: 16px 24px;
    background-color: #2196f3;
    color: #fff;
    font-size: 20px;
    font-weight: 600;
}

.dialog-header-wa {
    flex: 0 0 auto;
    margin: 0;
    padding: 16px 10px 0px 10px!important;
    background-color: #51CD5E;
    color: #fff;
    font-size: 20px;
    font-weight: 600;

}

.dialog-header-wa-flex {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    /* margin: auto; */
}

.dialog-buttons {
    flex: 0 0 auto;
    display: flex;
    padding: 8px;
    align-items: center;
    justify-content: flex-end;
    height: 60px;
}

.dialog-btn1 {
    width: 130px;
    height: 40px;
    color: #fff;
    background-color: #2196f3;
    border-radius: 5px;
    border: none;
    margin: 10px;
    font-weight: 600;
}
.dialog-btn1:disabled {
    background-color:#68b8fafd; 
    opacity: 0.6;
}

.dialog-content {
    color: #1b1f23;
    /* width: 600px; */
}


.dialog-body-default {
    display: grid;
    grid-template-columns: auto auto;
    gap: 10px;
    overflow: hidden;
    height: 100%;
}

.dialog-body-default1 {
    display: grid;
    grid-template-columns: auto;
    padding: 10px;
    gap: 10px;
    position: relative;
    min-height: 100px;

}

.MuiDialogContentText-root {
    margin-bottom: 0px !important;
}

.dialog-header-flex{
    display: flex;
    flex-direction: row;
    width: 100%;
}
.dialog-header-flex-lft{
    display: flex;
    flex-direction: row;
    width: calc(100% - 20px);
}
.dialog-header-flex-rht{
    display: flex;
    flex-direction: row;
    width: 20px;
    margin-top: 5px;
    cursor: pointer;
}
.dyanamicHeaderMainDiv{
    max-height: calc(80% - 64px);
    margin-top: 10%;

}

.wraptime_flx {
    display: flex;
    gap: 20px;
    flex-direction: column; /* Stack the items vertically */
    align-items: center; /* Horizontally center the items */
    justify-content: center; /* Vertically center the items */
    /* height: 100vh; Full viewport height, optional */
  }