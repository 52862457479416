.Customform-body {
    display: grid;
    grid-template-columns: auto auto;
    padding: 10px;
    gap: 10px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.Customform-fields {
    padding: 5px;
    width: 100%;
}


.Customform-main-box {
    margin: 10px;
    box-shadow: 1px 5px 18px rgb(0 0 0 / 12%);
    border: 1px solid #e7e7e7;
    border-radius: 6px;
}

.Customform-category-title {
    margin-left: 2px;
    font-weight: 600;
    color: #3e98c7;
    padding: 5px;
    padding-top: 7px;
}
.Customform-ticket-category-title {
    word-wrap: break-word;
    white-space: nowrap;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
}

.Customform-category-icon {
    width: 25px;
    left: -6px;
    position: relative;
    color: #3e98c7;
    padding: 5px;
}

.Customform-field-cnt {
    width: 100%;
}

.lead-filter-container .Customform-field-cnt {
    width: 54%;
}

.Customform-catagory {
    padding: 15px 15px 3px 17px;
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 60px;;

}

.Customform-catagory-rht{
    width: 20%;
    
}
.Customform-save-bts{
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
}

.Customform-catagory-lft{
    width: 80%;
    display: flex;
    flex-direction: row;
}
.Customform-save-icon {
    color: #3e98c7;
    padding: 10px;
    cursor: pointer;
}

.Customform-save-icon:hover {
    background: rgb(84 166 224 / 10%);
    color: #54A6E0;
    border-radius: 50%;
}

.Customform-ring{
    width: 35px;
    height: 35px;
    margin: 5px;
    padding: 5px;
}

.Customform-ring:hover {
    background: rgb(84 166 224 / 10%);
    border-radius: 50%;
    
}
.meeting-status{
    color: #3e98c7;
    padding: 10px;
    cursor: pointer;
    height: 42px;
    width: 38px;
}
.meeting-status:hover{
    background: rgb(84 166 224 / 10%);
    height: 42px;
    width: 38px;
    border-radius:50%
}


.preview_container svg {
    width: 16px !important;
    height: 17px !important;
    top: 8px !important;

}

.preview_container {
    height: 28px !important;
    line-height: 26px !important;
    font-size: 13px !important;
    padding-top: 3px !important;
}

.Customform-underline-flx {
    display: flex;
    flex-direction: row;
    padding-left: 16px;
    padding-top: 3px;
}

.Customform-underline-lft-div {
    height: 2px;
    width: 35px;
    border-radius: 14px;
    background-color: #3e98c7;
    margin-right: 4px;
}

.Customform-underline-rht-div {
    height: 2px;
    width: 10px;
    background-color: #3e98c7;
    border-radius: 14px;
}

.Customform-underline {
    width: 100%;
    background-color: #ced4da;
    height: 1px;
    margin-top: 5px;
}

.Customform-header{
    box-shadow: 0px 5px 10px rgb(0 0 0 / 6%);
    border-bottom: 1px solid #e1e1e1;
}