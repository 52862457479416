@media screen and (max-width:1640px) {}

@media screen and (max-width:1180px) {
    .select_crm {
        height: 100vh;
        background: none;
    }
}

@media screen and (max-width:1100px) {
    .select_crm {
        height: 100vh;
        background: none;
    }
}

@media screen and (max-width:1024px) {
    .select_crm {
        height: 100vh;
        background: none;
    }
}

@media screen and (max-width:980px) {}

@media screen and (max-width:800px) {
    .mobile_hide {
        display: none !important;
    }
}

@media screen and (max-width:768px) {
    .mobile_hide {
        display: none !important;
    }

    .rhtlogin {
        margin: 0px auto !important;
        width: 100%;
        background: none;
    }

    .select_crm {
        height: 55vh !important;
        background: none;
    }

    .loginborder {
        padding: 20px;
    }

}

@media screen and (max-width:767px) {
    .mobile_hide {
        display: none !important;
    }

}

@media screen and (max-width:640px) {
    .mobile_hide {
        display: none !important;
    }
}

@media screen and (max-width:577px) {

    .mobile_hide {
        display: none !important;
    }


}

@media screen and (max-width:480px) {
    .mobile_hide {
        display: none !important;
    }
}